.entry-success-container {
  padding: 1rem;
}

.entry-social {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.entry-social-button {
  margin-right: 1rem;
}

.entry-social-button:last-child {
  margin-right: 0;
}

.button--social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.2rem;
  border-radius: 0.1rem;
  border: none;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  letter-spacing: 0;
  text-transform: none;
  box-shadow: none;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  outline: 0;
}

.button--share-facebook {
  background-color: #3b5998;
}

.button--share-twitter {
  background-color: #00aced;
}

@media only screen and (max-width: 479px) {
  .entry-social {
    flex-direction: column;
  }
  .entry-social-button {
    margin: 0 0 1rem 0;
    flex: 1;
    width: 92%;
  }
}
