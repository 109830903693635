input,
textarea,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.form-container {
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
  padding: 1rem;
  line-height: 1.5;
}

.form__header {
  margin-bottom: 1rem;
}

.form__header-title {
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
}

.form__header-notes,
.form__form-error {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.form__form-error {
  color: #ff3323;
}

.form__footer {
  clear: both;
  padding-top: 0.5rem;
}

.form__submit {
  text-align: left;
}
