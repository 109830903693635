/*.entry-form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}*/

.entry-form-inline-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.entry-form-inline-row .form__row {
  width: 95%;
  margin-right: 5%;
}

.entry-form-inline-row .form__row:last-child {
  width: 95%;
  margin-right: 0;
}

@media only screen and (max-width: 500px) {
  .entry-form-inline-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .entry-form-inline-row .form__row {
    width: 100%;
    margin-right: 0;
  }

  .entry-form-inline-row .form__row:last-child {
    width: 100%;
    margin-right: 0;
  }
}
