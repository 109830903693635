select {
  font-size: 1.1em;
  width: 100%;
  border-style: none;
  outline: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  position: relative;
  background-image: url(./dropdown.svg);
  background-position: 98% center;
  background-repeat: no-repeat;
}
option[value=''][disabled] {
  display: none;
}

option {
  color: #111;
  background-color: #fff;
}
