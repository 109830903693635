.form__section {
  margin: 0 0 1.5rem 0;
  overflow: visible;
  padding: 0;
}

.form__section-title {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1rem;
}
